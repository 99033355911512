<template>
    <svg enable-background="new 0 0 212.6 85" version="1.1" viewBox="0 0 212.6 85" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"  aria-hidden="true">

        <ellipse class="st0" transform="matrix(.9915 -.1299 .1299 .9915 -4.3669 22.947)" cx="173.7" cy="44.9" rx="30" ry="28.6"/>
        <path class="st1" d="m170 16.6c-7.8 8.3-11.9 19.6-10.4 31.4 1.4 10.4 6.9 19.4 14.9 25.5 1 0 1.9-0.1 2.9-0.2 16.4-2.2 28-16.6 26-32.2-2.1-15.7-17-26.6-33.4-24.5z"/>
        <rect x="56" y="34.3" width="98.3" height="23.8"/>
        <rect x="97.7" y="8.3" width="65.2" height="23.8"/>
        <path class="st2" d="m109 17.1c-0.3 0-0.4-0.2-0.4-0.4v-0.9c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v2.6c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.9c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v3.9c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-2.8c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-1.5c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v0.9c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-2.5c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.9c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-3.9c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h2.8c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v1.5c0 0.3-0.2 0.4-0.4 0.4h-1.8z"/>
        <path class="st2" d="m115.5 24.6c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.1c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-1.1c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v1.7c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-2.9c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-10c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h2.9c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v1.7c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-1.1c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.1c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v8.8z"/>
        <path class="st2" d="m124.3 27.7c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-10.1c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h3c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-3zm0.9-12.5c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v8.8c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.3c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.3z"/>
        <path class="st2" d="m139.2 27.2c0 0.3-0.1 0.4-0.3 0.4h-1.9c-0.3 0-0.5-0.2-0.5-0.4l-0.9-4.6c0-0.1-0.1-0.2-0.3-0.2h-1c-0.1 0-0.2 0.1-0.2 0.2v4.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14c0-0.3 0.2-0.4 0.4-0.4h4.9c0.3 0 0.6 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v4.8c0 0.3-0.1 0.6-0.3 0.7l-0.7 0.7c-0.1 0.1-0.1 0.2-0.1 0.4l1.1 5.4zm-2.6-11.4c0-0.1 0-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.6c-0.1 0-0.2 0.1-0.2 0.2v4.3c0 0.1 0.1 0.2 0.2 0.2h1.6c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-3.5z"/>
        <path class="st2" d="m141 13.2c0-0.3 0.2-0.4 0.4-0.4h5.5c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-3c-0.1 0-0.2 0.1-0.2 0.2v2.3c0 0.1 0.1 0.2 0.2 0.2h2.4c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-2.4c-0.1 0-0.2 0.1-0.2 0.2v4.5c0 0.1 0.1 0.2 0.2 0.2h3c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-5.5c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m149 13.2c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.5 0.2 0.6 0.4l2.2 6.8c0 0.1 0.1 0.1 0.1 0.1s0.1-0.1 0.1-0.2v-6.7c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v14c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.5-0.2-0.6-0.4l-2.3-6.7c0-0.1-0.1-0.1-0.1-0.1-0.1 0-0.1 0.1-0.1 0.2v6.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <rect x="5.2" y="34.3" width="47.6" height="23.8"/>
        <path class="st2" d="m13.7 53.6c-0.3 0-0.4-0.2-0.5-0.4l-2.2-14c0-0.3 0.1-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.5 0.4l1.3 9.1c0 0.1 0.1 0.1 0.1 0.1 0.1 0 0.1 0 0.1-0.1l1.3-9.1c0-0.3 0.2-0.4 0.5-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4l-2.2 14c0 0.3-0.2 0.4-0.5 0.4h-2.8z"/>
        <path class="st2" d="m22.7 53.6c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.3c-0.2-0.2-0.3-0.5-0.3-0.7v-10.2c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h3c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-3zm0.9-12.4c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.3-0.1 0.4v8.8c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.3c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.3z"/>
        <path class="st2" d="m32.1 53.6c-0.3 0-0.5-0.1-0.7-0.3l-1.3-1.3c-0.2-0.2-0.3-0.5-0.3-0.7v-10.2c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h3c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-3.1zm0.9-12.4c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v8.8c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.3c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.3z"/>
        <path class="st2" d="m47 53.2c0 0.3-0.1 0.4-0.3 0.4h-1.8c-0.3 0-0.5-0.2-0.5-0.4l-0.9-4.6c0-0.1-0.1-0.2-0.3-0.2h-1c-0.1 0-0.2 0.1-0.2 0.2v4.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14c0-0.3 0.2-0.4 0.4-0.4h4.9c0.3 0 0.6 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v4.8c0 0.3-0.1 0.6-0.3 0.7l-0.8 0.7c-0.1 0.1-0.1 0.2-0.1 0.4l1.1 5.4zm-2.6-11.4c0-0.1 0-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.6c-0.1 0-0.2 0.1-0.2 0.2v4.3c0 0.1 0.1 0.2 0.2 0.2h1.6c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-3.5z"/>
        <path class="st2" d="m187.6 32.5c0.9-0.2 1.2-0.8 1.4-1.8 0.3-1.3-0.4-1.8-2.1-0.9 0 0-0.2 0.8-0.3 1.5 0 1 0.2 1.4 1 1.2z"/>
        <path class="st2" d="m188.7 34.5c-0.2-1.5-1.3-0.3-1.7 0.9l0.3 2.1 0.7 4.7 0.1 0.7 0.1 0.9 0.1 0.7c-0.1 0.6-0.3 0.9-0.6 0.9-0.5 0.1-1.3-0.3-2.6-1.3-1-0.9-0.8 0.7-0.5 1.3 1.5 1.5 3.1 2.3 4 2h0.1c0.5-0.2 1.2-1.2 1.3-1.3v-0.1c0.1-0.4 0-1.4 0-1.7-0.1-0.5-0.1-0.9-0.3-1.2l-1-8.6z"/>
        <path class="st2" d="m168.4 44.2c0.4 1.8-0.7 3.4-2.7 3.9-1.3 0.3-2.7 0.3-4.1 0.1-0.1 0.3-0.2 0.6-0.3 0.8-0.4 0.8-1.2 0.3-1.3-0.7 0.1-0.2 0.1-0.3 0.2-0.5-0.3-0.1-0.7-0.2-1-0.3-0.8-0.3-0.6-1.4 0.6-2.3 0.4 0.2 0.7 0.3 1 0.5 0.5-1.8 0.8-3.7 1-5.6-1.1-0.6-2.4-1.3-4-2-0.4-1-0.3-2.9 0.5-2.6 1.3 0.5 2.6 1.2 3.7 1.9 0-0.4 0.1-0.8 0.1-1.2 0.6-0.9 2-1.5 1.8-0.2 0 0.8-0.1 1.7-0.2 2.5 2.6 1.9 4.3 3.9 4.7 5.7zm-1.3 0.8c-0.3-1.1-1.4-2.5-3.5-3.9-0.3 1.7-0.7 3.5-1.2 5.1 1.3 0.3 2.4 0.3 3.5 0.1 1-0.3 1.3-0.7 1.2-1.3z"/>
        <path class="st2" d="m169.8 43.8c-0.4-1.8-0.5-3.6-0.4-5 0.1-1.1 1.3-1 1.8 0.5-0.4 1.6-0.3 2.6 0 3.7 0.2 0.9 0.6 1.5 1.1 1.4 0.6-0.2 0.9-1.2 0.7-2-0.1-0.6-0.6-1-1.4-0.9-0.2 0-0.3 0-0.4-0.3-0.1-0.6 0.2-1.6 1.4-1.9 0.6 0.2 1.4 0.7 1.7 2 0.6 2.5-0.7 4.9-2.1 5.2-1 0.3-1.9-0.8-2.4-2.7z"/>
        <path class="st2" d="m182.1 41.3c-0.9 1.9-2.1 3.1-3.1 3.3-0.9 0.2-2-0.7-2.4-2.5-0.2-0.7-0.2-1.3-0.2-1.9-1.2 0.5-2.7 0.9-4.6 1.1-0.3 0.1-0.6 0-0.7-0.2-0.2-0.7 0.5-1.6 0.8-1.9 2.5-0.3 3.9-0.6 4.7-0.9 0.4-1.2 1.3-2 1.9-2.2 0.4-0.1 0.8 0.1 1 0.6 0.2 0.9-0.3 1.9-1.8 2.8 0 0.5 0.1 1 0.1 1.3 0.3 1.2 0.8 1.7 1.2 1.6 0.5-0.1 1.2-0.7 2-2.2 0.8-1.1 1.1 0.5 1.1 1.1z"/>
        <path class="st2" d="m196.3 36.8c-0.6 1.3-1.8 2.9-3 3.2s-2.2-0.7-2.6-2.4c-0.6-2.5 0.5-5.1 1.9-5.4 0.6-0.2 1.2 0.1 1.4 1.1 0.3 1.2-0.2 2.7-1.5 4.2l-0.1 0.1c0.3 0.3 0.7 0.4 1.1 0.3 0.7-0.2 1.1-0.8 1.7-1.9 0.7-1.4 1.1 0 1.1 0.8zm-4.4-0.4c0.7-0.9 0.9-1.4 0.8-1.9 0-0.2-0.2-0.3-0.3-0.2-0.5 0.1-0.8 1-0.5 2.1z"/>
        <path class="st2" d="m182.8 56.8c-1.9 2.9-2.5 3.3-3.1 3.5-0.8 0.2-1.2-0.9-1.7-2.8-0.2-0.9-0.4-1.2-0.5-1.1-0.7 0.2-1.6 2.9-2.4 5.8-0.2 0.7-0.9 0.7-1 0.2-1.1-4.6-1.4-5-1.6-5-0.4 0.1-0.9 1.8-1.6 5.3-0.1 0.8-0.9 0.7-1 0.1l-1.4-5.9c0.1-1 1.5-1.8 1.7-0.9l0.4 2.5c0.8-3.1 1.7-4.1 2.2-4.2 1-0.3 1.6 2 2.1 4.1 0.8-2.2 1.8-4.3 3-4.6 0.8-0.2 1.3 0.8 1.9 3.2 0.2 0.7 0.3 1.1 0.5 1 0.2 0 0.5-0.3 1.9-2.5 0.7-1.2 0.8 0.5 0.6 1.3z"/>
        <path class="st2" d="m188.7 55.4c-1.5 2.3-2.5 3.4-3.5 3.7-0.9 0.2-1.8-0.6-2.3-2.3v0.1c-0.7 1.1-0.9-0.7-0.7-1.4l0.3-0.4c-0.1-2.3 0.9-4.5 2.3-4.9 0.6-0.1 1 0.2 1.2 0.9 0.3 1.2-0.2 2.8-1.7 4.6 0.3 0.7 0.8 1.2 1.3 1 0.5-0.1 1.2-0.8 2.5-2.8 0.7-1.1 0.8 0.7 0.6 1.5zm-5-1.2c0.3-0.6 0.7-1.3 0.6-1.6 0-0.1-0.1-0.1-0.1-0.1-0.3 0.1-0.5 0.8-0.5 1.7z"/>
        <path class="st2" d="m194.3 54.6c-0.7 1.8-2.2 3.1-3.1 3.3-0.8 0.2-1.8-0.7-2.3-2.6l-0.1 0.1c-0.7 1.1-0.9-0.7-0.7-1.4l0.4-0.6c-0.1-2.2 0.8-3.6 1.6-3.8 0.5-0.1 0.9 0.2 1.1 0.8 0.2 1-0.1 2.2-0.9 3.6 0.5 1.4 0.9 1.8 1.3 1.7 0.6-0.1 1.4-1.1 1.9-2.1 0.4-1.4 0.8 0.3 0.8 1z"/>
        <path class="st2" d="m195.5 53.5c1.7-0.8 2.4-0.3 2.1 1-0.2 1-0.5 1.5-1.4 1.7-0.7 0.2-1-0.3-1-1.2 0-0.7 0.3-1.5 0.3-1.5zm-1.5-9.1c2-0.2 3.7 0.6 4.1 2.2 0.4 1.7-0.7 3.8-1.8 5.9-0.9 0.4-1.6 0.2-1.1-0.8 0.7-1.4 1.4-2.7 1.2-3.6-0.2-0.8-1.3-1.4-2.7-1.3-1 0-0.6-1.3 0.3-2.4z"/>
        <rect x="36.4" y="8.3" width="58.1" height="23.8"/>
        <path class="st2" d="m47.8 17.1c-0.3 0-0.4-0.2-0.4-0.4v-0.9c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v2.6c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.9c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v3.9c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-2.8c-0.3 0-0.5-0.1-0.7-0.3l-1.5-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-1.5c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v0.9c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-2.5c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.9c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-3.9c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h2.8c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v1.5c0 0.3-0.2 0.4-0.4 0.4h-1.7z"/>
        <path class="st2" d="m56.5 12.8c0.3 0 0.4 0.2 0.5 0.4l2.3 14c0 0.3-0.1 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.5-0.4l-0.3-2.2c0-0.1-0.1-0.2-0.2-0.2h-2c-0.1 0-0.2 0.1-0.2 0.2l-0.3 2.2c0 0.3-0.2 0.4-0.5 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4l2.3-14c0-0.3 0.2-0.4 0.5-0.4h2.8zm-2.2 9.4c0 0.1 0.1 0.2 0.2 0.2h1.2c0.1 0 0.2-0.1 0.2-0.2l-0.6-4.6c0-0.1-0.1-0.1-0.1-0.1-0.1 0-0.1 0-0.1 0.1l-0.8 4.6z"/>
        <path class="st2" d="m65.2 23.9c-0.3 0-0.5-0.1-0.6-0.4l-1.4-3.6-0.1 7.4c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.5 0.2 0.6 0.4l2.3 6.3s0 0.1 0.1 0.1c0 0 0.1 0 0.1-0.1l2.2-6.3c0.1-0.2 0.3-0.4 0.6-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v14c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-7.4l-1.4 3.6c-0.1 0.3-0.3 0.4-0.6 0.4h-1z"/>
        <path class="st2" d="m72.7 13.2c0-0.3 0.2-0.4 0.4-0.4h5.5c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-3c-0.1 0-0.2 0.1-0.2 0.2v2.3c0 0.1 0.1 0.2 0.2 0.2h2.4c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-2.4c-0.1 0-0.2 0.1-0.2 0.2v4.5c0 0.1 0.1 0.2 0.2 0.2h3c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-5.5c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m80.7 13.2c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.5 0.2 0.6 0.4l2.3 6.8c0 0.1 0.1 0.1 0.1 0.1 0.1 0 0.1-0.1 0.1-0.2v-6.7c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v14c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.5-0.2-0.6-0.4l-2.3-6.7c0-0.1-0.1-0.1-0.1-0.1s-0.1 0.1-0.1 0.2v6.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m66.4 47.2c-0.3 0-0.4-0.2-0.4-0.4v-1.6c0-0.3 0.2-0.4 0.4-0.4h3.1c0.3 0 0.4 0.2 0.4 0.4v6.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-3c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7v-10.2c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h3c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v1.7c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-1.1c0-0.1-0.1-0.3-0.1-0.4h-0.2c-0.1-0.1-0.2-0.1-0.4-0.1h-1.2c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.2-0.1 0.4v8.8c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.2c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-3.2c0-0.1-0.1-0.2-0.2-0.2h-0.6z"/>
        <path class="st2" d="m71.7 39.2c0-0.3 0.2-0.4 0.4-0.4h5.5c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-3c-0.1 0-0.2 0.1-0.2 0.2v2.3c0 0.1 0.1 0.2 0.2 0.2h2.4c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-2.4c-0.1 0-0.2 0.1-0.2 0.2v4.5c0 0.1 0.1 0.2 0.2 0.2h3c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-5.5c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m85.9 40.8c0 0.3-0.1 0.6-0.2 0.8l-3.7 9.4c0 0.1 0 0.2 0.1 0.2h3.3c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-5.8c-0.3 0-0.4-0.2-0.4-0.4v-1.6c0-0.3 0.1-0.6 0.1-0.8l3.7-9.4c0-0.1 0-0.2-0.1-0.2h-3c-0.3 0-0.4-0.2-0.4-0.4v-1.6c0-0.3 0.2-0.4 0.4-0.4h5.5c0.3 0 0.4 0.2 0.4 0.4v1.6z"/>
        <path class="st2" d="m89.7 53.6c-0.3 0-0.5-0.1-0.7-0.3l-1.4-1.3c-0.2-0.2-0.3-0.5-0.3-0.7v-10.2c0-0.3 0.1-0.6 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3h3c0.3 0 0.5 0.1 0.7 0.3l1.4 1.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-3zm0.9-12.4c-0.1 0-0.3 0.1-0.4 0.1l-0.1 0.1c-0.1 0.1-0.1 0.3-0.1 0.4v8.8c0 0.1 0.1 0.3 0.1 0.4l0.1 0.1c0.1 0.1 0.2 0.1 0.4 0.1h1.3c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.3z"/>
        <path class="st2" d="m96.9 39.2c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.5 0.2 0.6 0.4l2.3 6.8c0 0.1 0.1 0.1 0.1 0.1s0.1-0.1 0.1-0.2v-6.7c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v14c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.5-0.2-0.6-0.4l-2.3-6.7c0-0.1-0.1-0.1-0.1-0.1-0.1 0-0.1 0.1-0.1 0.2v6.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m114.1 40.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-4.9c-0.3 0-0.4-0.2-0.4-0.4v-14c0-0.3 0.2-0.4 0.4-0.4h4.9c0.3 0 0.5 0.1 0.7 0.3l1.4 1.3zm-2.3 1.4c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.6c-0.1 0-0.2 0.1-0.2 0.2v9.6c0 0.1 0.1 0.2 0.2 0.2h1.6c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8z"/>
        <path class="st2" d="m116.2 39.2c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v5.6c0 0.1 0.1 0.2 0.2 0.2h2c0.1 0 0.2-0.1 0.2-0.2v-5.6c0-0.3 0.2-0.4 0.4-0.4h1.8c0.3 0 0.4 0.2 0.4 0.4v14c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-5.6c0-0.1-0.1-0.2-0.2-0.2h-2c-0.1 0-0.2 0.1-0.2 0.2v5.6c0 0.3-0.2 0.4-0.4 0.4h-1.8c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m125.8 39.2c0-0.3 0.2-0.4 0.4-0.4h5.5c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-3c-0.1 0-0.2 0.1-0.2 0.2v2.3c0 0.1 0.1 0.2 0.2 0.2h2.4c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-2.4c-0.1 0-0.2 0.1-0.2 0.2v4.5c0 0.1 0.1 0.2 0.2 0.2h3c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-5.5c-0.3 0-0.4-0.2-0.4-0.4v-14z"/>
        <path class="st2" d="m137.7 41.2c-0.1 0-0.2 0.1-0.2 0.2v9.6c0 0.1 0.1 0.2 0.2 0.2h0.7c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-4.5c-0.3 0-0.4-0.2-0.4-0.4v-1.6c0-0.3 0.2-0.4 0.4-0.4h0.7c0.1 0 0.2-0.1 0.2-0.2v-9.6c0-0.1-0.1-0.2-0.2-0.2h-0.7c-0.3 0-0.4-0.2-0.4-0.4v-1.6c0-0.3 0.2-0.4 0.4-0.4h4.5c0.3 0 0.4 0.2 0.4 0.4v1.6c0 0.3-0.2 0.4-0.4 0.4h-0.7z"/>
        <path class="st2" d="m147.8 40.4c0.2 0.2 0.3 0.5 0.3 0.7v10.1c0 0.3-0.1 0.6-0.3 0.7l-1.4 1.4c-0.2 0.2-0.5 0.3-0.7 0.3h-4.9c-0.3 0-0.4-0.2-0.4-0.4v-14c0-0.3 0.2-0.4 0.4-0.4h4.9c0.3 0 0.5 0.1 0.7 0.3l1.4 1.3zm-2.4 1.4c0-0.1-0.1-0.3-0.1-0.4l-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.1h-1.6c-0.1 0-0.2 0.1-0.2 0.2v9.6c0 0.1 0.1 0.2 0.2 0.2h1.6c0.1 0 0.3-0.1 0.4-0.1l0.1-0.1c0.1-0.1 0.1-0.2 0.1-0.4v-8.8z"/>
        <path d="m134.4 79.5c0.3-1.1 0.5-2.2 0.9-3.2 1-3 2.6-5.8 4.4-8.6 1.2-1.8 2.6-3.6 4.2-5.2l0.1-0.1c0.2-0.1 0.5-0.2 0.6-0.1 0.3 0.1 0.2 0.4 0 0.6-0.3 0.4-0.7 0.8-1.1 1.2-3.2 3.7-5.7 7.7-7.3 12.1-0.5 1.4-0.8 2.9-0.9 4.4 0 0.2-0.2 0.4-0.3 0.6-0.2-0.1-0.5-0.1-0.6-0.3-2.4-2.8-5.2-5.2-8.2-7.5-3.6-2.6-7.5-4.9-11.9-6.4-4.1-1.4-8.4-2.1-12.8-2.3-3.3-0.1-6.6-0.1-9.9-0.1h-0.6v-0.2c0.5-0.1 1-0.1 1.4-0.2 3.8-0.2 7.6-0.2 11.4 0.1 4.9 0.4 9.7 1.4 14 3.4 5.9 2.7 11.1 6.3 15.4 10.7 0.3 0.2 0.8 0.6 1.2 1.1z"/>
    </svg>
</template>

<style>
    .st0{fill:#E83363;}
    .st1{fill:#E50051;}
    .st2{fill:#FFFFFF;}
</style>