<template>
  <TransitionRoot as="template" :show="open" >
    <Dialog as="div" class="relative z-30" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full sm:flex-col items-start justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
             
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div :class="{ 'sm:flex sm:items-start': showIcon }" class="">
      
                    <div v-if="showIcon" class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>

                    <div class="mt-3 text-center sm:mt-0 sm:text-left" :class="{ 'sm:ml-4': showIcon }">
                      <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                        <Logo class=" mx-auto my-2 h-20 w-auto"/>
                        <header>
                 
                        </header>
                        
                        </DialogTitle>

                        <div class="mt-2">
                          <p class="text-sm text-gray-500">
                            <main>
                              <slot name="content">
                                {{ text }}
                              </slot>
                            </main>
                          </p>
                        </div>
                        
                    </div>

              </div>

              <footer>
                <slot name="footer">
                  <div class="hidden">
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        
                    <button type="button"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="open = false">Sluiten</button>
                    
                    <!--  
                    <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="closeModal()" ref="cancelButtonRef">Sluiten</button>
                    -->
          
                  </div>
                </div>
                </slot>
              </footer>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
//import { ref } from 'vue'
//const open = ref(false)
import Logo from './Logo.vue'
</script>

<script>
  export default {
    name: 'Modal',
    props : ['ModalContent',],
    data() {
      return {
        open: false,
        title: null,
        text: null,
        showIcon: null,
        isModalVisible: false,
      }
    },
    watch: {
      // opens modal if it gets content
      ModalContent() { 

        if (this.ModalContent) {
          this.open = true;
        } else {
          this.open = false;
        }
        
        console.log("modal content changed")
      }
    },

  }
</script>