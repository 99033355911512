<template>
  <ConfirmWarning v-if="unconfirmed"/>
  <Alert v-if="alert" :alertContent="alert" />
  <Notification :notificationContent="notification"></Notification>
  <Nav />
  <RouterView />
  <FooterSection />
</template>


<script setup>
import FooterSection from './components/FooterSection.vue'
import Nav from './components/Nav.vue'
import Notification from './components/Notification.vue'
import ConfirmWarning from './components/ConfirmWarning.vue'
import Alert from './components/Alert.vue'
</script>

<script>
export default {
  name: "app",
  data() {
    return {
      // Notification
      notification: [],    
      alert:[],
      unconfirmed: false,  
    }
  },
  components: {
    Notification,
  },
  mounted() {
    this.globals()
    if (this.userVar.user) {
      if (!this.userVar.user.confirmed) {
        this.unconfirmed = true
      } else {
        //console.log(this.$route.params.confirmed_msg)
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('confirmed_msg')) {
          
          let msg = urlParams.get('confirmed_msg')
          let supporting_msg = ""
          let status = "success"
          if (msg == "Account bevestigd!" || msg == "Account is al bevestigd" ) {
            supporting_msg = "Je kun nu volledig gebruik maken van het platform."
          } else {
            status = 'error'
          } 

          this.notify(msg, supporting_msg, status, 8000)

        } else {
          this.welcomeNotification()
        }
   
      }
      
    } else {
      let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('confirmed_msg')) {
          
          let msg = urlParams.get('confirmed_msg')
          let supporting_msg = ""
          let status = "success"
          if (msg == "Account bevestigd!" || msg == "Account is al bevestigd" ) {
            supporting_msg = "Je kun nu volledig gebruik maken van het platform."
          } else {
            status = 'error'
          } 

          this.notify(msg, supporting_msg, status, 8000)
        }
    }
    if (this.globalVar.Message.length) {
      this.alert = this.globalVar.Message
    }
  },
  methods: {
    globals() {
      console.log("Globals", this.globalVar)
      console.log("User", this.userVar)
      console.log("CSRF", this.csrfToken)
    },
    welcomeNotification() {
      const lastAuthLogin = new Date(this.userVar.user.lastLogin)
      const tenSecAgo = new Date(Number(new Date()) - 2000)
      // displays welcome message if app loaded within 2 sec
      if (lastAuthLogin > tenSecAgo ) {
        this.notify(`Hallo ${this.userVar.user.firstName}!`, "Je bent ingelogd", "success", 4000)
      }
    },
    
  }
  
}
</script>
