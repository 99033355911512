<template>
    <div class="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-50 ">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="rounded-lg bg-rose-600 p-2 shadow-lg sm:p-3">
          <div class="flex flex-wrap items-center justify-between">

            <div class="flex  w-full  flex-1 items-center">
              <span class="flex rounded-lg bg-rose-800 p-2">
                <BellAlertIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p class="ml-3 truncate font-sm text-white">
                <span class="md:hidden">Bevestig je emailadres.</span>
                <span class="hidden md:inline">{{ subline }}</span>
              </p>
            </div>

            <div class="mt-2 w-full flex-shrink-0 sm:mt-0 sm:w-auto" v-if="showButton">
              <button @click="sendValidation" :disabled="isDisabled"
              :class="[isDisabled ? 'opacity-90 cursor-not-allowed' : '', '']"
              class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-rose-600 shadow-sm hover:bg-rose-50">
                   {{ msg }}
                    <EnvelopeIcon v-if="!isDisabled" class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
             </button>
            </div>
       
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup>
import { BellAlertIcon } from '@heroicons/vue/24/outline'
import { EnvelopeIcon } from '@heroicons/vue/20/solid'
</script>


<script>

  export default {
    data() {
      return {
        isDisabled: false,
        msg: 'Nog een bevestigingsmail versturen',
        subline: null,
        showButton:false,
      }
    },
    mounted() {
      if (this.userVar.roles.some(element => element == "supplier")) { this.subline = 'Een beheerder moet je account nog goedkeuren, voor je een activiteit kunt toevoegen.'  }
      else if (this.userVar.roles.some(element => element == "referrer")) {this.showButton= true; this.subline = 'Bevestig je emailadres om doorverwijzingen te kunnen doen.'  }
      else if (this.userVar.roles.some(element => element == "user")) {this.showButton= true; this.subline = 'Bevestig je emailadres om je te kunnen aanmelden voor activiteiten.'  }
    },
    methods: {

        sendValidation() { 

            fetch(`${this.userVar.urls.validation_api}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFTOKEN': `${this.csrfToken.csrf}`,
            },
            })
            .then(response => Promise.all([response.status, response.json()]))
            .then(([status, data]) => {
  
                if (status == 202) {
                    this.isDisabled =  true
                    this.msg = data.msg
                } else if (status == 429) {
                  this.isDisabled =  true
                  console.error(data.detail)
                  this.subline = "Controleer je spam box, misschien heb je daar mail ontvangen."
                  this.msg = "Te veel pogingen gedaan"
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                this.msg = data.msg

            throw error
            })

      }, // sendValidation
    }
}
</script>