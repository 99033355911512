<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">


      <TransitionRoot as="div" :show="show" >
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
      </TransitionRoot>




      <div class="flex w-full flex-col items-center space-y-4 sm:items-end z-10">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <div v-if="notificationContent[0]"  class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex items-start">

                <div class="flex-shrink-0">

                    <CheckCircleIcon v-if="notificationContent[3] === 'success'" class="h-6 w-6 text-green-400" aria-hidden="true" />

                    <XCircleIcon v-if="notificationContent[3] === 'error'" class="h-6 w-6 text-red-400" aria-hidden="true" />
                    
                    <ExclamationCircleIcon v-if="notificationContent[3] === 'warning'" class="h-6 w-6 text-orange-400" aria-hidden="true" />
                </div>

                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900" v-html="notificationContent[1]"></p>
                  <p class="mt-1 text-sm text-gray-500" v-html="notificationContent[2]"></p>
                </div>

                <div class="ml-4 flex flex-shrink-0 invisible">
                  <button type="button" @click="show = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                    <span class="sr-only">Sluiten</span>
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>

              </div>
            </div>
          </div>
        </transition>
      </div>


    </div>

  </template>
  
  <script setup>
  import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
  import { XMarkIcon } from '@heroicons/vue/20/solid'

import {TransitionChild, TransitionRoot } from '@headlessui/vue'

</script>


<script>
export default {
  name: 'Notification',
  props: ['notificationContent'],
  data() {
    return {
      show: false
    }
  },
  watch: {
    // creates focus on notifications
    notificationContent() {
      if (this.notificationContent[0]) {
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 1000)
      }
    }
  }
}

</script>
