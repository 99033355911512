<template>
    <svg viewBox="104.811 99.518 312.1 101.9" >
    <polygon class="kduew" points="243.311 113.618 240.711 113.618 233.411 149.418 223.811 196.518 226.411 196.518"></polygon>
    <polygon class="kduew" points="250.411 99.518 247.811 99.518 237.711 149.418 227.111 201.418 229.611 201.418"></polygon>
    <polygon class="kduew" points="275.411 109.518 267.311 149.418 261.511 177.718 263.911 177.718 264.111 177.718 277.911 109.518"></polygon>
    <polygon class="kduew" points="226.811 173.718 235.211 132.218 232.711 132.218 229.211 149.418 224.311 173.718 226.611 173.718"></polygon>
    <polygon class="kduew" points="247.711 162.418 250.311 162.418 254.211 142.818 251.711 142.818 250.411 149.418"></polygon>
    <polygon class="kduew" points="254.611 149.418 252.011 162.418 254.311 162.418 254.511 162.418 258.511 142.818 255.911 142.818"></polygon>
    <polygon class="kduew" points="250.811 201.418 270.011 107.018 267.411 107.018 258.811 149.418 248.211 201.418 250.611 201.418"></polygon>
    <polygon class="kduew" points="257.411 189.818 275.711 99.518 273.211 99.518 263.111 149.418 254.811 189.818 257.311 189.818"></polygon>
    <polygon class="kduew" points="246.111 149.418 242.511 167.118 244.911 167.118 245.111 167.118 250.011 142.818 247.511 142.818"></polygon>
    <polygon class="kduew" points="253.711 103.618 251.211 103.618 241.911 149.418 231.311 201.418 233.911 201.418"></polygon>
    <g transform="matrix(1, 0, 0, 1, -36.289028, -270.681671)">
        <path class="kduew" d="M148,429.1c-2.1,0-3.8-0.5-5-1.6c-1.2-1-1.8-2.5-1.9-4.4c0-1.4,0.1-2.7,0.5-3.9c0.4-1.2,1-2.2,1.8-3.1
                    c0.8-0.9,1.8-1.6,3-2.1c1.2-0.5,2.5-0.8,4-0.8c0.9,0,1.8,0.1,2.7,0.3c0.9,0.2,1.8,0.6,2.8,1.1l-0.4,2.3h-0.2
                    c-0.8-0.7-1.6-1.2-2.5-1.5c-0.9-0.3-1.9-0.5-2.9-0.5c-1.1,0-2.1,0.2-3,0.7c-0.8,0.4-1.5,1-2.1,1.8c-0.5,0.7-1,1.6-1.2,2.5
                    c-0.3,1-0.4,1.9-0.4,2.9c0,1.5,0.5,2.6,1.3,3.4c0.8,0.8,2,1.2,3.6,1.2c0.7,0,1.3-0.1,1.9-0.2c0.6-0.1,1.2-0.3,1.7-0.5l0.8-3.9h-4.1
                    l0.3-1.7h6.1l-1.3,6.6c-0.4,0.2-0.9,0.4-1.2,0.5c-0.4,0.1-0.8,0.3-1.4,0.5c-0.5,0.1-1,0.2-1.5,0.3C149.2,429,148.6,429.1,148,429.1
                    z"></path>
        <path class="kduew" d="M170.2,413.4l-0.4,1.7h-8.1l-0.9,4.3h8.1l-0.4,1.7h-8.1l-1.2,5.8h8.1l-0.4,1.7H157l3.1-15.3H170.2z"></path>
        <path class="kduew" d="M184,428.8h-2.1l2.7-13l-6,8.8h-1.2l-2.4-9.2l-2.7,13.4h-1.9l3.1-15.3h2.8l2.3,8.5l5.7-8.5h2.8L184,428.8z"></path>
        <path class="kduew" d="M201.3,413.4l-0.4,1.7h-8.1l-0.9,4.3h8.1l-0.4,1.7h-8.1l-1.2,5.8h8.1l-0.4,1.7h-10.1l3.1-15.3H201.3z"></path>
        <path class="kduew" d="M214.6,413.4l-0.4,1.7h-8.1l-0.9,4.3h8.1l-0.4,1.7H205l-1.2,5.8h8.1l-0.4,1.7h-10.1l3.1-15.3H214.6z"></path>
        <path class="kduew" d="M226.5,428.8H224l-4.6-13.7l-2.8,13.7h-1.9l3.1-15.3h3.1l4.2,12.5l2.5-12.5h1.9L226.5,428.8z"></path>
        <path class="kduew" d="M244.2,415.2h-5.5l-2.8,13.6h-2.1l2.8-13.6h-5.5l0.3-1.7h13L244.2,415.2z"></path>
        <path class="kduew" d="M256.7,413.4l-0.4,1.7h-8.1l-0.9,4.3h8.1l-0.4,1.7h-8.1l-1.2,5.8h8.1l-0.4,1.7h-10.1l3.1-15.3H256.7z"></path>
    </g>
    <g transform="matrix(1, 0, 0, 1, -36.289028, -270.681671)">
        <path class="kduew" d="M325.9,413.5l-3,15.3h-2l1.5-7.5h-7.6l-1.5,7.5h-2l3-15.3h2l-1.2,6.1h7.6l1.2-6.1H325.9z"></path>
        <path class="kduew" d="M340.1,413.5l-0.3,1.7h-8.1l-0.9,4.3h8.1l-0.3,1.7h-8.1l-1.2,5.8h8.1l-0.3,1.7H327l3-15.3H340.1z"></path>
        <path class="kduew" d="M353.4,413.5l-0.3,1.7H345l-0.9,4.3h8.1l-0.3,1.7h-8.1l-1.2,5.8h8.1l-0.3,1.7h-10.1l3-15.3H353.4z"></path>
        <path class="kduew" d="M366.4,428.8H364l-4.2-6.1h-2.9l-1.2,6.1h-2.1l3-15.3h4.2c0.9,0,1.7,0.1,2.3,0.2c0.6,0.1,1.1,0.3,1.5,0.7
                    c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.4,0.9,0.4,1.5c0,1.3-0.3,2.4-1.1,3.4c-0.8,1-1.8,1.6-3.1,2L366.4,428.8z M363.8,417.3
                    c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.5-0.5-0.6c-0.3-0.2-0.6-0.4-1-0.4c-0.4-0.1-0.8-0.1-1.4-0.1h-2.3l-1.2,5.9h2.2
                    c0.7,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.6c0.4-0.3,0.8-0.8,1-1.3C363.7,418.4,363.8,417.8,363.8,417.3z"></path>
        <path class="kduew" d="M381.4,413.5l-0.3,1.7H373l-0.9,4.3h8.1l-0.3,1.7h-8.1l-1.2,5.8h8.1l-0.3,1.7h-10.1l3-15.3H381.4z"></path>
        <path class="kduew" d="M393.3,428.8h-2.4l-4.6-13.7l-2.7,13.7h-1.9l3.1-15.3h3.1L392,426l2.5-12.5h1.9L393.3,428.8z"></path>
        <path class="kduew" d="M412.3,413.5l-8.6,15.3H401l-2.5-15.3h2.1l2.1,13.5l7.5-13.5H412.3z"></path>
        <path class="kduew" d="M424.9,413.5l-0.3,1.7h-8.1l-0.9,4.3h8.1l-0.3,1.7h-8.1l-1.2,5.8h8.1l-0.3,1.7h-10.1l3-15.3H424.9z"></path>
        <path class="kduew" d="M438.3,413.5l-0.3,1.7h-8.1l-0.9,4.3h8.1l-0.3,1.7h-8.1l-1.2,5.8h8.1l-0.3,1.7h-10.1l3-15.3H438.3z"></path>
        <path class="kduew" d="M450.1,428.8h-2.4l-4.6-13.7l-2.7,13.7h-1.9l3-15.3h3.1l4.2,12.5l2.5-12.5h1.9L450.1,428.8z"></path>
    </g>
    </svg>
</template>

<style>
	.kduew{fill:#FFFFFF;}
</style>