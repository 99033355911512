<template>
    <div v-cloak v-if="alert" class="shadow-xl fixed z-50 inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">

      <div v-if="alertContent[0]" class="">
    
        <div
        class="border-l-4 p-4"
        :class="`border-${alertContent[0].status}-400 bg-${alertContent[0].status}-100`" 
        >
            <div class="flex">
                <div class="flex-shrink-0 pt-0.5">
                  <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" v-if="alertContent[0].status == 'green'" />
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true"  v-else-if="alertContent[0].status == 'red'" />
                  <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400"  v-else-if="alertContent[0].status == 'yellow'" />
                  <LightBulbIcon class="h-5 w-5 text-green-400" aria-hidden="true" v-else />
                </div>
                
                <div class="ml-3">
                  {{ alertContent.status }}
                  {{ alertContent.description }}
                    <p :class="`text-${alertContent[0].status}-700`" v-html="alertContent[0].description">
                    
                    
                    </p>
                </div>

                <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                        <button @click="alert=false" type="button" 
                        class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                        :class="`text-${alertContent[0].status}-800 hover:bg-${alertContent[0].status}-200 focus:ring-${alertContent[0].status}-600 focus:ring-offset-${alertContent[0].status}-100`"
                        
                        >
                                <span class="sr-only">Sluit melding</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>      
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="hidden text-green-700 text-red-700 text-yellow-700 border-red-400 border-green-400 border-yellow-400 bg-red-100 bg-yellow-100 bg-green-100 text-red-800 hover:bg-red-200 focus:ring-red-600 focus:ring-offset-red-100 text-green-800 hover:bg-green-200 focus:ring-green-600 focus:ring-offset-green-100 text-yellow-800 hover:bg-yellow-200 focus:ring-yellow-600 focus:ring-offset-yellow-100 "></div>
  </template>

<style>
[v-cloak] {
  display: none;
}
</style>

<script setup>
    import { XMarkIcon, CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon ,LightBulbIcon  } from '@heroicons/vue/20/solid'
</script>

<script>
  export default {
      name: 'Alert',
      props: ['alertContent'],
      data() {
          return {
              alert: true
          }
      }
  }
</script>